"use client";

import _e from "@/helpers/texts";
import Image from "next/image";
import styles from "./css/ContactBar.module.scss";
import { ContactLinks, SocialLinks } from "@/components/ContactBarLinks/ContactBarLinks";
import LoadingLink from "@/components/LoadingLink/LoadingLink";

export default function ContactBar({ content, menuToggle, transparent }: {
  readonly content: Record<string,any>,
  readonly menuToggle: Function,
  readonly transparent?: boolean,
}) {
  /* Make sure call center number is there and formed well */
  const phone = content.phone?.url ?? 'tel:+902122367441';
  const callCenter = (phone.indexOf('tel:') === -1 ? 'tel:' : '') + phone;

  return (
    <>
      <div className={`hidden lg:block ${styles['desktop-contact-bar']} ${transparent ? styles.transparent : ''}`}>
        <div className="container flex justify-between items-center">
          {/* Contact Links */}
          <div className="flex justify-start items-center gap-7 text-sm">
            <ContactLinks content={content} />
          </div>

          {/* Social Links */}
          <div className="flex justify-end items-center gap-6 text-xl">
            <SocialLinks content={content} />
          </div>
        </div>
      </div>

      <div className={`block lg:hidden ${styles['mobile-contact-bar']} ${transparent ? styles.transparent : ''}`}>
        <div className="mobile container-full lg:hidden flex justify-between items-center h-[75px]">
          <span className="flex justify-start text-[10px] flex-shrink-0 min-w-[14%]">
            <span className="flex flex-col items-center cursor-pointer leading-tight ms-1 mb-1" onClick={() => menuToggle()}>
              <Image className={`${styles['trans-invert']}`}
                src={`${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/images/global/hamburger-icon-dark.svg`}
                alt={ _e('Menü') }
                loading="lazy" //! priority={true}
                width={30}
                height={30} />
              { _e('Menü') }
            </span>
          </span>
          
          <LoadingLink className="flex justify-center items-center flex-shrink-1 px-2" href="/">
            <Image
              className={`${styles['mobile-logo']} ${styles['trans-invert']}`}
              src={`${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/public_assets/images/doping-hafiza-logo-14.svg`}
              loading="lazy" //! priority={true}
              alt="Doping Hafıza"
              width={244}
              height={70} />
          </LoadingLink>
          
          <LoadingLink className="flex justify-end text-[10px] flex-shrink-0 min-w-[14%]" href={callCenter} target="_blank">
            <span className="flex flex-col items-center">
              <Image className={`mb-1 ${styles['filter-dark']} ${styles['trans-invert']}`}
                src={`${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/icons/new724.svg`}
                alt={ _e('Çağrı Merkezi') }
                loading="lazy" //! priority={true}
                width={38}
                height={36} />
              { _e('Çağrı Merkezi') }
            </span>
          </LoadingLink>
        </div>
      </div>
    </>
  );
}
