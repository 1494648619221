"use client";

import Image from "next/image";
import { SocialLinks } from "../ContactBarLinks/ContactBarLinks";
import styles from "./css/Footer.module.scss";
import { useEffect, useState } from "react";
import DPIcon from "../DPIcon/DPIcon";
import ImageWithFallback from "../ImageWithFallback/ImageWithFallback";
import { linkUrl } from "@/helpers";
import _e from "@/helpers/texts";
import { LiveChatWidget } from "@livechat/widget-react";
import LoadingLink from "../LoadingLink/LoadingLink";
import WAWidget from "../WAWidget/WAWidget";

export default function Footer({ menuData, footerData, noChat }: {
  readonly menuData: Record<string,any>,
  readonly footerData: Record<string,any>,
  readonly noChat?: boolean,
}) {
  const mobileDownloads = false;

  const [ selectedMenu, setSelectedMenu ] = useState(0);
  const [ hasStickyFooter, setHasStickyFooter ] = useState<number>(0);
  const [ liveChatState, setLiveChatState ] = useState<"maximized" | "minimized" | "hidden" | undefined>('minimized');
  
  const maximizeLiveChat = () => {
    setLiveChatState('minimized');

    setTimeout(() => {
      setLiveChatState('maximized');
    }, 50);
  };

  const [ isMobile, setIsMobile ] = useState<boolean>(true);
  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);

  const [ timeMode, setTimeMode ] = useState<'wa'|'call'>('wa');

  useEffect(() => {
    const handleResize = () => {
      let mob = true;
      if(window && window?.innerWidth >= 1024) mob = false;
      
      setIsMobile(mob);
      setIsLoaded(true);
    };

    const timeCheck = () => {
      const n = new Date();
      const currentTime = n.getDay() ?? 0;
      const days = (process?.env?.NEXT_PUBLIC_TIME_MODE_DAYS ?? '4,5').split(',');

      setTimeMode((current: 'wa'|'call') => {
        if(days.includes(currentTime.toString())) {
          if(current !== 'call') return 'call';
        } else {
          if(current !== 'wa') return 'wa';
        }

        return current;
      });
    };

    timeCheck();

    const tcInt = setInterval(timeCheck, 60000);

    if(document?.querySelector('[data-footer-scroll-break-point]')) setHasStickyFooter(1);
    if(document?.querySelector('[data-footer-fast-menu]')) setHasStickyFooter(2);
    if(document?.querySelector('[data-sticky-phone]')) setHasStickyFooter(3);

    handleResize();

    window?.addEventListener('maximize-live-chat', maximizeLiveChat);
    window?.addEventListener('resize', handleResize);
    return () => {
      clearInterval(tcInt);
      window?.removeEventListener('maximize-live-chat', maximizeLiveChat);
      window?.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <footer className={`${styles['page-footer']} bg-white`}>
      <div className={`${styles['footer-top']} text-center lg:flex lg:justify-center lg:items-center`}>
        <SocialLinks content={menuData} type="footer" className="relative inline-block z-10 mx-2 text-2xl text-lava filter grayscale opacity-60 hover:filter-none hover:grayscale-0 hover:opacity-100 transition-all" />
      </div>
      <div className={`${styles['footer-body']}`}>
        <div className="container relative z-10">
          <div className="my-4 md:my-12">
            <ImageWithFallback
              src={menuData?.logo?.url ? linkUrl(process.env.NEXT_PUBLIC_API_ROOT ?? '', menuData?.logo?.url) : (process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/public_assets/images/doping-hafiza-logo-14.svg'}
              fallback={(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/public_assets/images/doping-hafiza-logo-14.svg'}
              alt={menuData?.logo?.alternativeText ?? menuData?.logo?.caption ?? 'Doping Hafıza'}
              width={menuData?.logo?.width ?? 244}
              height={menuData?.logo?.height ?? 70}
              className="w-[244px] h-[70px]"
            />
          </div>
          <div className="flex flex-wrap">
            {
              footerData?.menu?.map((item: Record<string,any>, index: number) => (
                <div key={item.id} className={`mb-6 w-full sm:w-1/2 lg:w-1/4 ${mobileDownloads ? 'xl:w-1/5' : ''}`}>
                  <div className={`cursor-default font-semibold flex items-center sm:text-charcoal ${selectedMenu === index ? 'text-lava' : ''}`} onClick={ () => setSelectedMenu(index) }>
                    {item.caption}
                    <DPIcon icon="arrow-circle-right" className={`ms-2 sm:hidden transition-all duration-300 ${selectedMenu === index ? 'rotate-90' : ''}`} />
                  </div>
                  <div className={`overflow-hidden h-auto transition-all duration-300 sm:!max-h-full ${selectedMenu === index ? 'max-h-screen' : 'max-h-0'}`}>
                    {
                      item?.sublink?.map((sub: Record<string,any>) => (
                        <div key={sub.id} className="py-1 pe-4">
                          <LoadingLink className="text-sm leading-4 hover:text-lava transition-all" target={sub.target === 'blank' ? '_blank' : '_self'} rel={sub.target === 'blank' ? 'noopener noreferrer' : ''} title={sub.title} href={sub.url}>{sub.caption}</LoadingLink>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
            {
              mobileDownloads && (
                <div className="mb-6 w-full xl:w-1/5 text-center xl:text-right">
                  { footerData?.app_store_url && (
                    <LoadingLink className="inline-block m-1" target="_blank" rel="noopener noreferrer" title={ _e('Doping Hafıza İOS Uygulamasını İndirin')} href={footerData.app_store_url}>
                      <Image
                        src="https://assets-dopinghafiza.dopingtech.net/assets/images/global/appstore-download.svg"
                        alt={ _e('App Store\'dan İndirin') }
                        width={182}
                        height={53}
                      />
                    </LoadingLink>
                  ) }
                  { footerData?.play_store_url && (
                    <LoadingLink className="inline-block m-1" target="_blank" rel="noopener noreferrer" title={ _e('Doping Hafıza Android Uygulamasını İndirin') } href={footerData.play_store_url}>
                      <Image
                        src="https://assets-dopinghafiza.dopingtech.net/assets/images/global/playstore-download.svg"
                        alt={ _e('Play Store\'dan İndirin') }
                        width={182}
                        height={53}
                      />
                    </LoadingLink>
                  ) }
                  { footerData?.app_gallery_url && (
                    <LoadingLink className="inline-block m-1" target="_blank" rel="noopener noreferrer" title={ _e('Doping Hafıza Uygulamasını AppGallery\'den İndirin') } href={footerData.app_gallery_url}>
                      <Image
                        src="https://assets-dopinghafiza.dopingtech.net/assets/images/global/app-gallery.svg"
                        alt={ _e('AppGallery\'den İndirin') }
                        width={182}
                        height={53}
                      />
                    </LoadingLink>
                  ) }
                </div>
              )
            }

            <div className="flex flex-col-reverse lg:flex-row lg:justify-center xl:justify-end items-center w-full">
              <div className="w-[60px] flex-shrink-0 mt-6 mb-2 lg:mt-0 lg:mb-0 lg:me-7">
                <LoadingLink href={footerData.etbis_url} target="_blank" rel="noopener noreferrer">
                  <ImageWithFallback
                    src={footerData?.etbis_image?.url ? linkUrl(process.env.NEXT_PUBLIC_API_ROOT ?? '', footerData?.etbis_image?.url) : process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/public_assets/images/etbis.png'}
                    fallback={`${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/public_assets/images/etbis.png"`}
                    alt="Doping Hafıza ETBİS'e Kayıtlıdır"
                    width={footerData?.etbis_image?.width ?? 60}
                    height={footerData?.etbis_image?.height ?? 69}
                    className="w-auto h-auto max-w-full"
                  />
                </LoadingLink>
              </div>
              <div className="w-[445px] max-w-[90%] flex-shrink-0">
                <ImageWithFallback
                  src={footerData?.payment_image?.url ? linkUrl(process.env.NEXT_PUBLIC_API_ROOT ?? '', footerData?.payment_image?.url) : process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/public_assets/images/pay-methods.webp'}
                  fallback={`${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/public_assets/images/pay-methods.webp"`}
                  alt="Doping Hafıza Ödeme Yöntemleri"
                  width={footerData?.payment_image?.width ?? 445}
                  height={footerData?.payment_image?.height ?? 34}
                  className="w-auto h-auto max-w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`bg-slate-100 pt-1 ${hasStickyFooter === 1 ? 'pb-[140px] md:pb-[182px] lg:pb-[176px]' : (hasStickyFooter === 2 ? 'pb-[86px] lg:pb-1' : (hasStickyFooter === 3 ? 'pb-[60px] md:pb-1' : 'pb-1'))}`}>
        <div className="container flex flex-col md:flex-row justify-between items-center">
          <div className="flex flex-col md:flex-row pb-4 md:pb-0 items-center">
            <LoadingLink className="w-[113px] md:w-[88px] pb-1 md:pb-0 md:pe-4 md:me-4 md:border-e-[1px] md:border-charcoal" href="https://dopingtech.net/" title="Dopingtech.net" target="_blank">
              <ImageWithFallback
                src={footerData?.brand_logo?.url ? linkUrl(process.env.NEXT_PUBLIC_API_ROOT ?? '', footerData?.brand_logo?.url) : (process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/public_assets/images/doping-logo.svg'}
                fallback={(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/public_assets/images/doping-logo.svg'}
                alt="Doping Technology"
                width={footerData?.brand_logo?.width ?? 88}
                height={footerData?.brand_logo?.height ?? 25}
                className="w-auto h-auto max-w-full"
              />
            </LoadingLink>
            <span className="text-xs text-charcoal text-center opacity-90">{footerData.branding}</span>
          </div>

          <div className="text-xs text-center md:text-end text-charcoal opacity-90">
            {footerData.copyright}
          </div>

        </div>
      </div>
      
      {
        !noChat && isLoaded ? (
          isMobile && timeMode === 'wa' ? (
            <WAWidget whatsapp={menuData?.whatsapp} phone={menuData?.phone} />
          ) : (
            <LiveChatWidget
              license="11147847"
              visibility={liveChatState}
            />
          )
        ) : (<></>)
      }
    </footer>
  );
}
